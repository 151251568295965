import { useEffect, useState } from 'react';
import css from './ItemView.module.scss';
import ExternalPage from 'monday-ui-react-core/dist/icons/ExternalPage';
import RatingStar from './RatingStar';
import { Link, Loader } from 'monday-ui-react-core';
import classNames from 'classnames';
import { useMondaySdk } from '../../hooks/useMondaySdk';
import { getUrlFromColumnValue } from '../../helpers/getUrlFromColumnValue';

export interface ProductDetails {
  name: string;
  images: string[];
  id: string;
  url?: string;
  description?: string;
  brand?: string;
  sku?: string;
  availability?: 'in_stock' | 'out_of_stock' | 'in_store_only' | 'online_only' | 'pre_order' | 'discontinued';
  price?: number;
  currency?: string;
  rating?: number;
}

interface ProductDetailsRowProps extends ProductDetails { }

interface ProductAvailabilityProps {
  availability?: string
}

const ProductAvailability: React.FC<ProductAvailabilityProps> = ({ availability }) => {
  if (!availability) {
    return <div className={css.ProductDetails__details__availability} />;
  }

  let message;
  let availabilityType;

  switch (availability) {
    case 'in_stock':
      message = 'In Stock';
      availabilityType = 'positive';
      break;
    case 'in_store_only':
      message = 'In Store Only';
      availabilityType = 'positive';
      break;
    case 'online_only':
      message = 'Online Only';
      availabilityType = 'positive';
      break;
    case 'pre_order':
      message = 'Pre-order';
      break;
    case 'discontinued':
      message = 'Discontinued';
      availabilityType = 'negative';
      break;
    case 'out_of_stock':
      message = 'Out of Stock';
      availabilityType = 'negative';
      break;
    default:
      message = availability?.replaceAll('_', ' ');
      break;
  }

  return (
    <div className={classNames(
      css.ProductDetails__details__availability,
      css[`ProductDetails__details__availability--${availabilityType}`]
    )}>
      {message}
    </div>
  );
};

const ProductDetailsRow: React.FC<ProductDetailsRowProps> = ({ name, images, rating, availability, price, currency, description, url }) => {
  const builtUrl = new URL(url!);

  return (
    <div className={css.ProductDetails}>
      {images?.length && (
        <div className={css.ProductDetails__thumbnail}>
          <img className={css.ProductDetails__thumbnail__image} src={images[0]} />
        </div>
      )}
      <div className={css.ProductDetails__details}>
        <div
          className={classNames(css.ProductDetails__details__shortened, css.ProductDetails__details__name)}
          dangerouslySetInnerHTML={{ __html: name! }}
        />
        {rating && [1, 2, 3, 4, 5].map(point => (
          <span
            key={`rating-star-${point}`}
            className={classNames(css.ProductDetails__details__ratingStar, {
              [css['ProductDetails__details__ratingStar--active']]: Math.round(rating) >= point
            })}
          >
            <RatingStar size={13} />
          </span>
        ))}
        {description && (
          <div
            className={css.ProductDetails__details__shortened}
            dangerouslySetInnerHTML={{ __html: description! }} />
        )}
        <div className={css.ProductDetails__details__pricingAndAvailability}>
          {price && (
            price.toLocaleString(undefined, { style: 'currency', currency, minimumFractionDigits: 2, maximumFractionDigits: 2 })
          )}
          <ProductAvailability availability={availability} />
          <Link
            componentClassName={css.ProductDetails__details__link}
            href={url}
            icon={ExternalPage}
            text={`Visit ${builtUrl.hostname.replace(/^www\./i, '')}`}
            iconPosition={Link.position.END}
          />
        </div>
      </div>
    </div>
  );
};

const ItemView = () => {
  const [products, setProducts] = useState<{ [columnId: string]: ProductDetails }>({});
  const [context, setContext] = useState<any>();
  const [columnLoadStatuses, setColumnLoadStatuses] = useState<{ [columnId: string]: boolean }>();
  const monday = useMondaySdk();

  const setColumnAsLoaded = (columnId: string) => {
    setColumnLoadStatuses(existing => ({ ...existing, [columnId]: true }));
  };

  const loadProductDetails = async (columnId: string, url: string) => {
    const result = await fetch(`https://product-vision.bebapps.com/api/product-details/products/from-url?url=${encodeURIComponent(url)}`);

    const body = await result.json();

    setProducts(existingProducts => {
      const cloned = { ...existingProducts };
      if (!body.productDetails.name) {
        delete cloned[columnId];
      } else {
        cloned[columnId] = body.productDetails;
      }
      return cloned;
    });
    setColumnAsLoaded(columnId);
  };

  const initialLoad = async () => {
    const { data: context } = await monday.get('context');
    setContext(context);
    const { data: { items: [item] } } = await monday.api(`
      query ($itemId: Int!) {
        items(ids: [$itemId]) {
          id
          column_values {
            id
            value
            type
          }
        }
      }
      `, {
      variables: {
        itemId: context.itemId
      }
    });

    const startingColumnLoadStatuses: { [columnId: string]: boolean } = {};
    item.column_values.forEach(({ id }: { id: string }) => {
      startingColumnLoadStatuses[id] = false;
    });
    setColumnLoadStatuses(startingColumnLoadStatuses);

    const columns = [] as any[];
    item.column_values.forEach(({ id, value: stringifiedValue, type }: any) => {
      const value = JSON.parse(stringifiedValue);
      if (!value) {
        setColumnAsLoaded(id);
        return;
      }

      const url = getUrlFromColumnValue(type, value);

      if (url) {
        loadProductDetails(id, url);
        columns.push({ id, url });
      } else {
        setColumnAsLoaded(id);
      }
    });

    monday.listen(['events', 'context'], (event: any) => {
      const { data, type } = event;

      if (type === 'context') {
        setContext(data);
        return;
      }

      const { itemIds, columnType, columnValue, columnId } = data;

      if (type === 'change_column_values' && itemIds?.includes(context.itemId)) {
        const url = getUrlFromColumnValue(columnType, columnValue);

        if (!url) {
          setProducts(existingProducts => {
            const cloned = { ...existingProducts };
            delete cloned[columnId];
            return cloned;
          });
        } else {
          loadProductDetails(columnId, url);
        }
      }
    });
  };

  useEffect(() => {
    initialLoad();
  }, []);

  const productEntries = Object.entries(products);

  if (!columnLoadStatuses || (!productEntries.length && Object.values(columnLoadStatuses).some(loaded => !loaded))) {
    return (
      <div className={classNames(css.Loader, { 'dark-app-theme': context?.theme === 'dark' })}>
        <div className={css.Loader__holder}>
          <Loader />
        </div>
      </div>
    );
  }

  return (
    <div className={classNames(css.ItemView, { 'dark-app-theme': context?.theme === 'dark' })}>
      {productEntries.length
        ? productEntries.map(([rowId, productDetails]) => (
          <ProductDetailsRow
            key={rowId}
            {...productDetails}
          />
        ))
        : (
          <div className={css.ItemView__none}>
            <div className={css.ItemView__none__message}>
              No products found for this item.
              <br />
               Try pasting a product link in one of its columns.
            </div>
          </div>)
      }
    </div>
  );
};

export default ItemView;
