import { fabric } from 'fabric';
import { useEffect, Fragment, useRef, useContext, memo } from 'react';
import { TextToolSettings } from '../../VisionBoard/Tools/TextTools';
import { Profile } from '../../VisionBoard/VisionBoard';
import CanvasContext from '../CanvasContext';
import FabricListener from '../FabricListener';
import { TextObject } from '../FabricText';

interface TextListenerProps {
  exitTextMode: () => void;
  updateData: (path: string, value: any, commit?: boolean) => void;
  isTextMode: boolean;
  updateProfile: (updates: Partial<Profile>) => void
  setEditingTextId: (id?: string) => void;
  setTextToolSettings: React.Dispatch<React.SetStateAction<TextToolSettings>>
  textToolSettings: TextToolSettings;
}

const TextListener: React.FC<TextListenerProps> = ({ exitTextMode, updateData, updateProfile, setEditingTextId, setTextToolSettings, isTextMode, textToolSettings }) => {
  const canvas = useContext(CanvasContext);
  const overlayRef = useRef<fabric.Object>();

  useEffect(() => {
    if (isTextMode) {
      canvas.perPixelTargetFind = false;

      overlayRef.current = new fabric.Rect({
        height: 1000000,
        width: 1000000,
        top: -500000,
        left: -500000,
        fill: 'transparent',
        hoverCursor: 'text',
        selectable: false
      });
      canvas.add(overlayRef.current);

      // TODO: Ensure overlay is always on the top even with other users dragging etc
      // bringOverlayToFrontRef.current = () => overlayRef.current?.bringToFront();
      // canvas.on('before:render', bringOverlayToFrontRef.current);
    } else {
      canvas.perPixelTargetFind = true;
      if (overlayRef.current) {
        canvas.remove(overlayRef.current);
      }
    }
  }, [isTextMode]);

  const textModeMouseUpHandler = ({ absolutePointer }: fabric.IEvent) => {
    const id = Math.round((Math.random() * 1000000000)).toString();

    const width = 300;

    const { fill, fontStyle, fontWeight, textAlign, fontSize } = textToolSettings;

    updateData(`texts.${id}`, {
      text: '',
      top: Math.round(absolutePointer!.y), // TODO: subtract half of line height if originY is set to top
      left: Math.round(absolutePointer!.x) + (width / 2),
      width,
      fill,
      fontStyle,
      fontWeight,
      textAlign,
      fontSize
    });

    updateProfile({ targetId: id });

    setEditingTextId(id);
    exitTextMode();
  };

  const textEditingExitedHandler = ({ target }: fabric.IEvent) => {
    if (!(target instanceof TextObject)) {
      return;
    }

    setEditingTextId();

    if (!target.text) {
      canvas.remove(target);
      updateData(`texts.${target.pvId}`, undefined);
    }
  };

  const textEditingEnteredHandler = ({ target }: fabric.IEvent) => {
    if (!(target instanceof TextObject)) {
      return;
    }

    const targetId = target.pvId;

    const {
      fontStyle,
      fontWeight,
      textAlign,
      fill,
      fontSize
    } = target;

    setTextToolSettings({
      fontStyle,
      fontWeight,
      textAlign,
      fill,
      fontSize
    });

    setEditingTextId(targetId);
    updateProfile({ targetId });
  };

  return (
    <Fragment>
      {isTextMode && <FabricListener eventname="mouse:up" handler={textModeMouseUpHandler} />}
      <FabricListener eventname="text:editing:exited" handler={textEditingExitedHandler} />
      <FabricListener eventname="text:editing:entered" handler={textEditingEnteredHandler} />
    </Fragment>
  );
};

export default memo(TextListener);
