import { fabric } from 'fabric';
import { BUTTON_COLORS } from '../constants';

const createPhotoControls = () => {
  const viewItemBackground = new fabric.Rect({
    height: 32,
    width: 46, // TODO: verify this width for small button with this text
    fill: BUTTON_COLORS.Primary.normal,
    rx: 8,
    ry: 8,
    originX: 'center',
    originY: 'center'
  });

  const viewItemText = new fabric.Text('View', {
    fontFamily: 'Roboto,sans-serif',
    fontSize: 14,
    fill: 'white',
    originX: 'center',
    originY: 'center'
  });

  const viewItem = new fabric.Group([viewItemBackground, viewItemText], {
    name: `Type:Button-Style:Primary-ViewItem=${name}`,
    left: 156,
    originX: 'center'
  });

  return new fabric.Group([viewItem]);
};

export default createPhotoControls;
