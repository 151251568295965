import css from './Tools.module.scss';
import { Tooltip } from 'monday-ui-react-core';
import classnames from 'classnames';

interface ToolProps {
  isActive?: boolean;
  disableTooltip?: boolean;
  onClick?: () => void;
  onMouseLeave?: () => void;
  onMouseEnter?: () => void;
  IconComponent: any;
  tooltip: string | null;
  iconStyle?: any;
}

const Tool: React.FC<ToolProps> = ({ isActive, onClick, IconComponent, tooltip, disableTooltip, onMouseLeave, onMouseEnter, iconStyle }) => (
  <div className={css.Tools__tool}>
    <Tooltip
      content={!disableTooltip && tooltip}
      immediateShowDelay={0}
    >
      <span
        className={css.Tools__iconWrapper}
        onMouseLeave={onMouseLeave}
        onMouseEnter={onMouseEnter}
      >
        <IconComponent
          className={classnames(css.Tools__icon, {
            [css['Tools__icon--active']]: isActive
          })}
          style={iconStyle}
          size="24"
          onClick={onClick}
        />
      </span>
    </Tooltip>
  </div>
);

export default Tool;
