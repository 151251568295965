import { Dialog } from 'monday-ui-react-core';
import { useState } from 'react';
import Tool from './Tool';

interface DialogToolProps {
  IconComponent: any;
  hideTooltipOnOpen?: boolean;
  position?: string;
  startingEdge?: string;
  toolIconStyle?: any;
  toolIconbackgroundColor?: string;
  tooltip: string;
  onOpen?: () => void;
}

const DialogTool: React.FC<DialogToolProps> = ({ children, IconComponent, hideTooltipOnOpen, position = 'bottom-start', startingEdge, toolIconStyle = {}, toolIconbackgroundColor, tooltip, onOpen }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [disableTooltip, setDisableTooltip] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  // const [hasEntered];

  // TODO: hide when icon goes off screen due to mouse leave

  return (
    <Dialog
      position={position}
      startingEdge={startingEdge}
      animationType="expand"
      content={children}
      showTrigger={['click', 'enter']}
      hideTrigger={['clickoutside', 'esckey']}
      useDerivedStateFromProps={true}
      onDialogDidShow={() => {
        onOpen?.();
        setIsOpen(true);
        if (hideTooltipOnOpen) {
          setDisableTooltip(true);
        }
      }}
      onDialogDidHide={() => {
        if (!hideTooltipOnOpen) {
          setDisableTooltip(false);
        }
        setIsOpen(false);
      }}
      isOpen={isOpen}
    >
      <Tool
        disableTooltip={disableTooltip}
        IconComponent={IconComponent}
        isActive={isOpen}
        tooltip={tooltip}
        onMouseLeave={() => {
          setIsHovered(false);
          if (!disableTooltip) {
            setDisableTooltip(isOpen);
          }
        }}
        iconStyle={{ ...toolIconStyle, ...(toolIconbackgroundColor && (isOpen || isHovered) ? { backgroundColor: toolIconbackgroundColor } : {}) }}
        onMouseEnter={() => {
          setIsHovered(true);
          setDisableTooltip(isOpen);
        }}
      />
    </Dialog>
  );
};

export default DialogTool;
