import { fabric } from 'fabric';
import { useEffect, useState, Fragment } from 'react';
import CanvasContext from './CanvasContext';

interface FabricCanvasProps {
  height: number;
  width: number;
  isDrawingMode: boolean;
  freeDrawingBrushColor: string;
  freeDrawingBrushWidth: number;
}

/* eslint-disable */
class TrackingPencilBrush extends (fabric.PencilBrush as any) {
  constructor(canvas: any) {
    super(canvas);

    this.decimate = 5;
  }

  _addPoint(point: { eq: (arg0: any) => any; }) {
    // TODO: send messages to draw on other screens
    return super._addPoint(point);
  }
}
/* eslint-enable */

const FabricCanvas: React.FC<FabricCanvasProps> = ({ children, height, width, isDrawingMode, freeDrawingBrushColor, freeDrawingBrushWidth }) => {
  const [canvas, setCanvas] = useState<fabric.Canvas>();

  useEffect(() => {
    canvas?.setWidth(width);
    canvas?.setHeight(height);
  }, [width, height]);

  useEffect(() => {
    setCanvas(new fabric.Canvas('canvas', {
      height,
      width,
      uniScaleKey: 'none',
      selectionColor: 'transparent',
      selectionBorderColor: '#0085FF',
      selectionLineWidth: 0.2,
      perPixelTargetFind: true,
      targetFindTolerance: 5,
      fireRightClick: true,
      fireMiddleClick: true,
      stopContextMenu: true,
      selection: false
    }));
  }, []);

  // TODO: move this into own component like TextMode
  useEffect(() => {
    if (!canvas) {
      return;
    }

    canvas.freeDrawingBrush = new TrackingPencilBrush(canvas) as any;
    canvas.freeDrawingBrush.color = freeDrawingBrushColor;
    canvas.freeDrawingBrush.width = freeDrawingBrushWidth;

    canvas.isDrawingMode = isDrawingMode;
  }, [isDrawingMode]);

  useEffect(() => {
    if (!canvas) {
      return;
    }

    canvas.freeDrawingBrush.color = freeDrawingBrushColor;
    canvas.freeDrawingBrush.width = freeDrawingBrushWidth;
  }, [freeDrawingBrushColor, freeDrawingBrushWidth]);

  return (
    <Fragment>
      <canvas id="canvas" />
      {canvas &&
        <CanvasContext.Provider value={canvas}>
          {children}
        </CanvasContext.Provider>
      }
    </Fragment>
  );
};

export default FabricCanvas;
