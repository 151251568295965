import css from './Tools.module.scss';
import classnames from 'classnames';
import { Children, cloneElement, memo, useRef } from 'react';

interface TopToolsWrapperProps {
  visible: boolean
}

const TopToolsWrapper: React.FC<TopToolsWrapperProps> = ({ children, visible }) => {
  const test = useRef(children);
  const lastChildren = test.current; // This doesn't work because the child is chaning I think :shrug:
  test.current = children;

  return (
    <div className={classnames(css.Wrapper, css['Wrapper--top'], {
      [css['Wrapper--top--visible']]: visible
    })}>
      <div className={classnames(css.Tools, css['Tools--top'])}>
        {children}
        {!visible && lastChildren}
      </div>
    </div>
  );
};

export default TopToolsWrapper;
