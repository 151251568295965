import { fabric } from 'fabric';
import { memo } from 'react';
import { Profile } from '../../VisionBoard/VisionBoard';
import FabricListener from '../FabricListener';

interface CursorListenerProps {
  updateProfile: (updates: Partial<Profile>) => void
}

const CursorListener: React.FC<CursorListenerProps> = ({ updateProfile }) => {
  const mouseMoveHandler = ({ absolutePointer, transform }: fabric.IEvent) => {
    updateProfile({
      x: Math.round(absolutePointer!.x),
      y: Math.round(absolutePointer!.y)
    });
  };
  const mouseOutHandler = ({ target }: fabric.IEvent) => {
    if (!target) {
      updateProfile({
        x: null,
        y: null
      });
    }
  };

  // TODO: listen for mouse out then remove x and y and targetId?
  return (
    <>
      <FabricListener
        eventname="mouse:out"
        handler={mouseOutHandler}
      />
      <FabricListener
        eventname="mouse:move"
        handler={mouseMoveHandler}
      />
    </>
  );
};

export default memo(CursorListener);
