import { memo, useContext, useEffect } from 'react';
import { useMondaySdk } from '../../../hooks/useMondaySdk';
import CanvasContext from '../CanvasContext';
import { DrawingObject } from '../FabricDrawing';
import { PhotoObject } from '../FabricPhoto/FabricPhoto';
import { TextObject } from '../FabricText';

interface KeyUpListenerProps {
  updateData: (path: string, value: any, commit?: boolean) => void;
  setIsTextMode: (isTextMode: boolean) => void;
  setIsDrawingMode: (isDrawingMode: boolean) => void;
}

const KeyUpListener: React.FC<KeyUpListenerProps> = ({ updateData, setIsDrawingMode, setIsTextMode }) => {
  const canvas = useContext(CanvasContext);
  const monday = useMondaySdk();

  useEffect(() => {
    const keyUpListener = (event: KeyboardEvent) => {
      switch (event.code) {
        case 'Escape':
          setIsDrawingMode(false);
          setIsTextMode(false);
          canvas.discardActiveObject();
          break;
        case 'Backspace':
        case 'Delete': {
          const target = canvas.getActiveObject();

          if (target instanceof DrawingObject) {
            canvas.remove(target);
            updateData(`drawings.${target.pvDrawingId}`, undefined);
          }

          if (target instanceof TextObject && !target.isEditing) {
            canvas.remove(target);
            updateData(`texts.${target.pvId}`, undefined);
          }
          break;
        }
        default:
          break;
      }
    };
    window.addEventListener('keyup', keyUpListener);

    return () => {
      window.removeEventListener('keyup', keyUpListener);
    };
  }, []);

  return null;
};

export default memo(KeyUpListener);
