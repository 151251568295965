import css from './Tools.module.scss';
import { useRef } from 'react';
import DialogTool from './DialogTool';
import { DialogContentContainer } from 'monday-ui-react-core';
import classNames from 'classnames';

interface StrokeWidthPickerProps {
  currentWidth: number;
  remoteWidth?: number;
  options?: number[];
  setWidth: (width: number) => void;
  setLocalWidth: (width: number) => void;
  IconComponent: any;
  theme?: string;
}

const defaultcolors = [2, 4, 6, 8];

const StrokeWidthPicker: React.FC<StrokeWidthPickerProps> = ({ options = defaultcolors, currentWidth, remoteWidth, setWidth, setLocalWidth, IconComponent, theme }) => {
  const revertStrokeTimeoutRef = useRef<any>();

  const onMouseEnterStrokeWidth = (width: number) => {
    clearTimeout(revertStrokeTimeoutRef.current);
    setLocalWidth(width);
  };

  const onMouseLeaveStrokeWidth = () => {
    revertStrokeTimeoutRef.current = setTimeout(() => {
      if (remoteWidth) {
        setLocalWidth(remoteWidth);
      }
    }, 400);
  };

  const onSelectStrokeWidth = (width: number) => {
    clearTimeout(revertStrokeTimeoutRef.current);
    setWidth(width);
  };
  return (
    <DialogTool
      IconComponent={({ size, ...props }: any) => (
        <div {...props}>
          <div style={{ width: size, height: size }} className={css.WidthIcon}>
            <div style={{ width: currentWidth + 4, height: currentWidth + 4 }} className={css.WidthIcon__dot} />
          </div>
        </div>
      )}
      hideTooltipOnOpen
      position='bottom'
      tooltip="Line width"
    >
      <DialogContentContainer className={classNames(css.InnerOptions, css['InnerOptions--horizontal'], { 'dark-app-theme': theme === 'dark' })}>
        {options.map((width: number) => (
          <button
            key={width}
            className={classNames(css.Color, css['Color--primaryTextColor'])}
            style={{ width: width + 6, height: width + 6 }}
            onMouseEnter={() => onMouseEnterStrokeWidth(width)}
            onClick={() => onSelectStrokeWidth(width)}
            onMouseLeave={onMouseLeaveStrokeWidth}
          >
          </button>
        ))}
      </DialogContentContainer>
    </DialogTool>
  );
};

export default StrokeWidthPicker;
