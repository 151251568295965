import { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import FabricCanvas from '../Fabric/FabricCanvas';
import { Loader } from 'monday-ui-react-core';
import FabricPhoto from '../Fabric/FabricPhoto/FabricPhoto';
import ButtonListener from '../Fabric/listeners/ButtonListener';
import PhotoListener from '../Fabric/listeners/PhotoListener';
import SelectionListener from '../Fabric/listeners/SelectionListener';
import ZoomListener from '../Fabric/listeners/ZoomListener';
import { useVisionBoard } from '../../hooks/useVisionBoard';
import ObjectChangingListener from '../Fabric/listeners/ObjectChangingListener';
import ObjectModifiedListener from '../Fabric/listeners/ObjectModifiedListener';
import FabricUser from '../Fabric/FabricPhoto/FabricUser';
import CursorListener from '../Fabric/listeners/CursorListener';
import DrawingListener from '../Fabric/listeners/DrawingListener';
import FabricDrawing from '../Fabric/FabricDrawing';
import Tools from './Tools/Tools';
import css from './VisionBoard.module.scss';
import TextListener from '../Fabric/listeners/TextListener';
import FabricText from '../Fabric/FabricText';
import TextTools, { TextToolSettings } from './Tools/TextTools';
import TopToolsWrapper from './Tools/TopToolsWrapper';
import DrawingTools, { DrawingToolSettings } from './Tools/DrawingTools';
import mondaySdk from 'monday-sdk-js';
import KeyUpListener from '../Fabric/listeners/KeyUpListener';
import classNames from 'classnames';
import { getUrlFromColumnValue } from '../../helpers/getUrlFromColumnValue';
import { fabric } from 'fabric';

const monday = mondaySdk();

const getImageUrl = (productUrl: string) => `https://product-vision.bebapps.com/api/product-details/products/image?url=${encodeURIComponent(productUrl)}`;

interface ImageItem {
  src: string;
  top: number;
  left: number;
  angle: number;
  scale: number;
}

interface Data {
  images?: {
    [itemId: string]: ImageItem
  },
  drawings?: {
    [id: string]: any
  },
  texts?: {
    [id: string]: any
  },
}

export interface Profile {
  name: string;
  color: string;
  targetId?: string | null;
  x: number | null;
  y: number | null;
}

const useMondayMe = () => {
  const [name, setName] = useState<string>();

  const loadMe = async () => {
    const { data: { me } } = await monday.api('query {  me {  name  }  }');
    setName(me.name);
  };

  useEffect(() => {
    loadMe();
  }, []);

  return { name };
};

const useMondayData = () => {
  const [context, setContext] = useState<any>();
  const [settings, setSettings] = useState<any>();
  const [productsUrls, setProductUrls] = useState<{ [key: string]: string }>();
  const [itemIds, setItemIds] = useState<number[]>();

  const listener = (method: string, type: string, data: any) => {
    if (method === 'listen') {
      if (type === 'context') {
        setContext(data);
      } else if (type === 'settings') {
        setSettings(data);
      } else if (type === 'itemIds') {
        setItemIds(data);
        // TODO: update items
      }
    } else if (method === 'event') {
      if (type === 'new_items') {
        // TODO: handle new items
      } else if (type === 'change_column_value') {
        // TODO: handle changed column values
      }
    }
  };

  const initialLoad = async () => {
    const { data: context } = await monday.get('context');
    const { data: settings } = await monday.get('settings');
    const { data: itemIds } = await monday.get('itemIds') as { data: number[] };

    setItemIds(itemIds);
    setSettings(settings);
    setContext(context);

    const { data: { boards: [board] } } = await monday.api(`
      query ($boardId: Int!) {
        boards(ids: [$boardId]) {
          items(limit: 1000) {
            id
            column_values {
              id
              value
              type
            }
          }
        }
      }
      `, {
      variables: {
        boardId: context.boardId
      }
    });

    const productUrls: { [key: string]: string } = {};

    const imagePromises: any[] = [];

    board.items.forEach((item: any) => {
      item.column_values.forEach(({ id, value: stringifiedValue, type }: any) => {
        const value = JSON.parse(stringifiedValue);
        if (!value) {
          return;
        }
        const url = getUrlFromColumnValue(type, value);

        if (url) {
          const image = new Image();
          image.src = getImageUrl(url);

          const testPromise = new Promise((resolve) => {
            image.onload = (e: any) => {
              resolve(true);
              productUrls[`${item.id}_${id}`] = url;
            };

            image.onerror = () => {
              resolve(true);
            };

            image.onabort = () => {
              resolve(true);
            };
          });

          imagePromises.push(testPromise);
        }
      });
    });

    await Promise.all(imagePromises);
    setProductUrls(productUrls);

    monday.listen(['context', 'events', 'itemIds', 'settings'], ({ method, type, data }) => listener(method, type, data));
  };

  useEffect(() => {
    initialLoad();
  }, []);

  return {
    settings,
    itemIds,
    context,
    productsUrls
  };
};

const VisionBoard: React.FC<{ sessionToken: string, boardId: string }> = ({ sessionToken, boardId }) => {
  const [isDrawingMode, setIsDrawingMode] = useState(false);
  const [isTextMode, setIsTextMode] = useState(false);
  const [editingTextId, setEditingTextId] = useState<string>();
  const [editingDrawingId, setEditingDrawingId] = useState<string>();
  const [canvasSize, setCanvasSize] = useState<{ height: number, width: number }>({ height: window.innerHeight, width: window.innerWidth });
  const [textToolSettings, setTextToolSettings] = useState<TextToolSettings>({ fontSize: 24 }); // TODO: default based on dark vs light mode
  const [drawingToolSettings, setDrawingToolSettings] = useState<DrawingToolSettings>({ strokeWidth: 4, stroke: '#323338' }); // TODO: dark vs light mode
  const { name: myName } = useMondayMe();

  // eslint-disable-next-line no-unused-vars
  const { context, itemIds, settings, productsUrls } = useMondayData();

  // eslint-disable-next-line no-unused-vars
  const { isLoading, data, updateData, profile, updateProfile, peers } = useVisionBoard<Data, Profile>({ id: `${boardId}-5`, applyPendingUpdatesLocally: false, artificialDelay: 200, token: sessionToken }); // TODO: use board id

  const updateDataRef = useRef<any>();
  const updateProfileRef = useRef<any>();

  const updateTextTool = useCallback((key: string, value: string | boolean | number, localOnly = false) => {
    if (!localOnly && editingTextId) {
      updateData(`texts.${editingTextId}.${key}`, value, true);
    }
    setTextToolSettings(settings => ({ ...settings, [key]: value }));
  }, [isLoading, editingTextId]);

  const updateDrawingTool = useCallback((key: string, value: string | boolean | number, localOnly = false) => {
    if (!localOnly && editingDrawingId) {
      updateData(`drawings.${editingDrawingId}.${key}`, value, true);
    }
    setDrawingToolSettings(settings => ({ ...settings, [key]: value }));
  }, [isLoading, editingDrawingId]);

  useEffect(() => {
    if (!isLoading) {
      if (!data.texts) {
        updateData('texts', {});
      }

      if (!data.texts) {
        updateData('drawings', {});
      }

      updateDataRef.current = updateData;
      updateProfileRef.current = updateProfile;
    }
  }, [isLoading]);

  useEffect(() => {
    if (!isLoading && myName) {
      updateProfile({ name: myName });
    }
  }, [isLoading, myName]);

  useEffect(() => {
    const handleWindowResize = () => {
      setCanvasSize({
        height: window.innerHeight,
        width: window.innerWidth
      });
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (!productsUrls || isLoading) {
      return;
    }

    if (!data.images) {
      updateData('images', {});
    }

    const entries = Object.entries(productsUrls);
    const columnCount = Math.ceil(Math.sqrt(entries.length));
    entries.forEach(([id, url], index) => {
      const existingImage = data.images?.[id];
      if (!existingImage) {
        const newImage = {
          left: Math.round(260 * ((index % columnCount) + 1)) + Math.floor((Math.random() * 60)) - 20,
          top: Math.round(200 * (Math.floor(index / columnCount) + 1)) + Math.floor((Math.random() * 60)) - 20,
          angle: Math.floor((Math.random() * 60)) - 30,
          scale: 0.7 + (Math.floor((Math.random() * 60)) / 100),
          src: getImageUrl(url)
        };

        updateData(`images.${id}`, newImage, true);
      } else if (existingImage.src !== getImageUrl(url)) {
        updateData(`images.${id}.src`, getImageUrl(url), true); // TODO: test this
      }
    });

    if (data.images) {
      Object.keys(data.images).forEach(id => {
        if (!Object.keys(productsUrls).includes(id)) {
          updateData(`images.${id}`, undefined, true);
        }
      });
    }
  }, [productsUrls, isLoading]);

  const grayscaleFilters = useMemo(() => {
    return [new fabric.Image.filters.Grayscale()];
  }, []);

  if (isLoading || !updateDataRef.current || !updateProfileRef.current || !data.images) {
    return <div className={classNames(css.Loader, { 'dark-app-theme': context?.theme === 'dark' })}>
      <div className={css.Loader__holder}>
        <Loader />
      </div>
    </div>;
  }

  return (
    <div className={classNames(css.VisionBoard, { 'dark-app-theme': context?.theme === 'dark' })}>
      <FabricCanvas
        height={canvasSize.height}
        width={canvasSize.width}
        isDrawingMode={isDrawingMode}
        freeDrawingBrushColor={drawingToolSettings.stroke}
        freeDrawingBrushWidth={drawingToolSettings.strokeWidth || 2}
      >
        {data.images && Object.entries(data.images).map(([id, { left, src, top, angle, scale }]) => {
          const mondayItemId = Number(id.match(/[0-9]*/)?.[0]);
          return (
            <FabricPhoto
              key={id}
              src={src}
              left={left}
              top={top}
              angle={angle}
              filters={itemIds?.includes(mondayItemId) ? undefined : grayscaleFilters}
              scale={scale}
              name={id}
              controller={peers.find(peer => peer.profile.targetId === id)}
              mondayItemId={mondayItemId}
            />
          );
        })}
        {data.drawings && Object.entries(data.drawings).map(([id, { left, path, stroke, strokeWidth, top, angle }]) => (
          <FabricDrawing
            angle={angle}
            id={id}
            left={left}
            path={path}
            stroke={editingDrawingId === id ? drawingToolSettings.stroke : stroke}
            strokeWidth={editingDrawingId === id ? drawingToolSettings.strokeWidth || strokeWidth : strokeWidth}
            top={top}
            key={id}
            controller={peers.find(peer => peer.profile.targetId === id)}
          />
        ))}
        {data.texts && Object.entries(data.texts).map(([id, { left, text, top, width, angle, fontStyle, fontWeight, textAlign, fill, fontSize }]) => (
          <FabricText
            id={id}
            left={left}
            text={text}
            top={top}
            angle={angle}
            key={id}
            controller={peers.find(peer => peer.profile.targetId === id)}
            width={width}
            isEditing={editingTextId === id}
            fontWeight={fontWeight}
            fontStyle={fontStyle}
            textAlign={textAlign}
            fill={editingTextId === id ? textToolSettings.fill : fill}
            fontSize={fontSize}
          />
        ))}
        <PhotoListener />
        <ButtonListener updateProfile={updateProfileRef.current} editingTextId={editingTextId} />
        <SelectionListener
          setEditingDrawingId={setEditingDrawingId}
          setEditingTextId={setEditingTextId}
          setDrawingToolSettings={setDrawingToolSettings}
          setTextToolSettings={setTextToolSettings}
        />
        <ZoomListener isDrawingMode={isDrawingMode} />
        <CursorListener updateProfile={updateProfileRef.current} />
        {peers
          .filter(peer => (peer.profile.x || peer.profile.x === 0) && peer.profile.y && peer.profile.name)
          .map(peer => (
            <FabricUser
              key={peer.id}
              color={peer.profile.color || '#0085ff'}
              left={peer.profile.x!}
              top={peer.profile.y!}
              name={peer.profile.name!}
            />
          ))
        }
        <DrawingListener updateData={updateDataRef.current} />
        <KeyUpListener
          setIsDrawingMode={setIsDrawingMode}
          setIsTextMode={setIsTextMode}
          updateData={updateDataRef.current}
        />
        <ObjectChangingListener updateData={updateDataRef.current} />
        <ObjectModifiedListener updateData={updateDataRef.current} />
        <TextListener
          exitTextMode={() => setIsTextMode(false)}
          setEditingTextId={setEditingTextId}
          updateData={updateDataRef.current}
          updateProfile={updateProfileRef.current}
          isTextMode={isTextMode}
          setTextToolSettings={setTextToolSettings}
          textToolSettings={textToolSettings}
        />
        <Tools
          isDrawingMode={isDrawingMode}
          isTextMode={isTextMode}
          setIsDrawingMode={setIsDrawingMode}
          setIsTextMode={setIsTextMode}
          theme={context?.theme}
        />
        {
          <TopToolsWrapper visible={!!(isTextMode || editingTextId || isDrawingMode || editingDrawingId)}>
            {(isTextMode || editingTextId) && (
              <TextTools
                {...textToolSettings}
                remoteFill={editingTextId && data.texts?.[editingTextId]?.fill}
                updateTextTool={updateTextTool}
                theme={context?.theme}
              />
            )}
            {(isDrawingMode || editingDrawingId) && (
              <DrawingTools
                {...drawingToolSettings}
                remoteStroke={editingDrawingId && data.drawings?.[editingDrawingId]?.stroke}
                remoteStrokeWidth={editingDrawingId && data.drawings?.[editingDrawingId]?.strokeWidth}
                updateDrawingTool={updateDrawingTool}
                theme={context?.theme}
              />
            )}
          </TopToolsWrapper>
        }
      </FabricCanvas>
    </div>
  );
};

export default VisionBoard;
