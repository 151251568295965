import { useContext, useEffect, memo, useRef } from 'react';
import CanvasContext from './CanvasContext';

interface FabricListenerProps {
  eventname: string
  handler: (event: any) => void;
}

const FabricListener: React.FC<FabricListenerProps> = ({ eventname, handler }) => {
  const canvas = useContext(CanvasContext);
  const handlerRef = useRef(handler);

  useEffect(() => {
    handlerRef.current = handler;
  }, [handler]);

  useEffect(() => {
    const onOffHandler = (event: any) => handlerRef.current(event);
    canvas.on(eventname, onOffHandler);

    return () => { canvas.off(eventname, onOffHandler); };
  }, []);

  return null;
};

export default memo(FabricListener);
