import { fabric } from 'fabric';
import { useContext, useEffect, memo, useRef } from 'react';
import { Peer } from './../../hooks/useVisionBoard';
import { Profile } from './../VisionBoard/VisionBoard';
import CanvasContext from './CanvasContext';
import { applyCustomCorners, ensureObjectIsOnScreen } from './helpers';

interface FabricDrawingProps {
  stroke: string;
  strokeWidth: number;
  path: string;
  top: number;
  left: number;
  angle: number;
  id: string;
  controller?: Peer<Profile>;
}

const CONTROL_VISIBILTY = {
  mb: false,
  mr: false,
  ml: false,
  mt: false
};

export class DrawingObject extends fabric.Path {
  pvDrawingId?: string;
}

const FabricDrawing: React.FC<FabricDrawingProps> = ({ id, path, stroke, top, left, angle, strokeWidth, controller }) => {
  const canvas = useContext(CanvasContext);
  const drawingRef = useRef<DrawingObject>();

  const applyChanges = (drawing: DrawingObject) => {
    // setFilters(photo);
    // TODO: investigate performance setting all at once vs individual useEffects
    drawing.set({
      angle,
      top,
      left
    });

    ensureObjectIsOnScreen(drawing);

    canvas.requestRenderAll();

    drawing.animate('stroke', stroke, {
      easing: fabric.util.ease.easeInSine,
      onChange: canvas.renderAll.bind(canvas),
      duration: 200
    });

    drawing.animate('strokeWidth', strokeWidth, {
      easing: fabric.util.ease.easeInSine,
      onChange: canvas.renderAll.bind(canvas),
      duration: 200
    });
  };

  useEffect(() => {
    const photo = drawingRef.current;
    if (!photo) {
      return;
    }

    photo.set('selectable', !controller);

    // TODO: set come color indication of who the controller is
    if (controller) {
      photo.bringToFront();
      photo.sendBackwards(); // This is a hack to push the photo back behind the user
    }

    canvas.requestRenderAll();
  }, [controller]);

  if (drawingRef.current) {
    applyChanges(drawingRef.current);
  }

  useEffect(() => {
    const drawing = new DrawingObject(path, {
      angle,
      top,
      left,
      stroke,
      strokeWidth,
      hasControls: false, // TODO: add support for controls
      fill: 'transparent'
    });

    // TODO: add support for controls
    // applyCustomCorners(drawing);

    drawing.pvDrawingId = id;
    drawingRef.current = drawing;
    drawing.setControlsVisibility(CONTROL_VISIBILTY);
    canvas.add(drawing);

    return () => { canvas.remove(drawingRef.current!); };
  }, []);

  return null;
};

export default memo(FabricDrawing);
