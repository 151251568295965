import { fabric } from 'fabric';

export const applyCustomCorners = (object: fabric.Object) => object.set({
  borderColor: '#0085FF',
  cornerColor: '#0085FF',
  transparentCorners: false,
  cornerSize: 10,
  centeredRotation: true,
  cornerStyle: 'circle',
  borderScaleFactor: 0.4
});

export const ensureObjectIsOnScreen = (object: fabric.Object) => {
  if (!object.isOnScreen()) {
    object.setCoords();
  }
};
