export const getUrlFromColumnValue = (type: string, value: any) => {
  let url;

  switch (type) {
    case 'text':
      url = value;
      break;
    case 'long-text':
      url = value?.text;
      break;
    case 'link':
      url = value?.url;
      break;
    default:
      break;
  }

  if (url?.startsWith?.('https://')) {
    return url;
  }
};
