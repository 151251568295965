import Highlight from 'monday-ui-react-core/dist/icons/Highlight';
import { Fragment, memo } from 'react';
import ColorPicker from './ColorPicker';
import StrokeWidthPicker from './StrokeWidthPicker';

export interface DrawingToolSettings {
  stroke?: any;
  strokeWidth?: number;
  theme?: string;
}

interface DrawingToolsProps extends DrawingToolSettings {
  updateDrawingTool: (key: keyof DrawingToolSettings, value: boolean | string | number, localOnly?: boolean) => void;
  remoteStroke?: string;
  remoteStrokeWidth?: number;
}

const DrawingTools: React.FC<DrawingToolsProps> = ({ updateDrawingTool, stroke, remoteStroke, strokeWidth = 2, remoteStrokeWidth, theme }) => {
  return (
    <Fragment>
      <StrokeWidthPicker
        IconComponent={Highlight}
        currentWidth={strokeWidth}
        setWidth={color => updateDrawingTool('strokeWidth', color)}
        setLocalWidth={color => updateDrawingTool('strokeWidth', color, true)}
        remoteWidth={remoteStrokeWidth}
        theme={theme}
      />
      <ColorPicker
        IconComponent={Highlight}
        currentColor={stroke}
        setColor={color => updateDrawingTool('stroke', color)}
        setLocalColor={color => updateDrawingTool('stroke', color, true)}
        remoteColor={remoteStroke}
        theme={theme}
      />
    </Fragment>
  );
};

export default memo(DrawingTools);
