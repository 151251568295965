import { Fragment, useRef, useState } from 'react';
import { TextField, Button, SplitButton } from 'monday-ui-react-core';
import css from './UrlInput.module.scss';
import Media from 'react-media';

const URL_REGEX = /^http(s)?:\/\/\w*\.\w{2,}/i;

interface UrlInputProps {
  onCreateItem: () => void;
  isCreatingItem: boolean;
  icon?: any;
  onChange: (value: string, isValidUrl: boolean) => void;
  errorMessage?: string;
}

const UrlInput: React.FC<UrlInputProps> = ({ onCreateItem, isCreatingItem, icon, onChange, errorMessage }) => {
  const [validation, setValidation] = useState<{ status?: string, text?: string; }>({});
  const [value, setValue] = useState('');

  const errorTimeout = useRef<any>();

  const onInputChange = (inputValue: string) => {
    setValue(inputValue);
    clearTimeout(errorTimeout.current);

    const isValidUrl = !!inputValue?.match(URL_REGEX);

    if (!inputValue) {
      setValidation({});
    } else if (isValidUrl) {
      setValidation({});
    } else {
      const newValidation = {
        status: 'error',
        text: 'Please enter a valid URL'
      };

      if (validation.status === 'success' || errorMessage) {
        setValidation(newValidation);
      } else {
        errorTimeout.current = setTimeout(() => {
          setValidation(newValidation);
        }, 500);
      }
    }

    // This needs to be done after setting validation to ensure no flicker happens
    onChange(inputValue, isValidUrl);
  };

  return (
    <Fragment>
      <TextField
        onChange={onInputChange}
        value={value}
        size={TextField.sizes.MEDIUM}
        validation={errorMessage
          ? { status: 'error', text: errorMessage }
          : validation}
        placeholder="Enter the URL for any product"
        iconName={icon}
      />
      <div className={css.UrlInput__buttons}>
        <Media queries={{
          medium: '(min-width: 350px) and (max-width: 499px)',
          large: '(min-width: 500px)'
        }}>
          {matches => (
            <Fragment>
              {matches.large && !isCreatingItem && (
                <Button kind={Button.kinds.TERTIARY}>
                  View item column values
                </Button>
              )}
              {/* TODO: Medium screen: View item column values */}
              {/* TODO: All screen: Create in x group, create sub item (if possible)  */}
              <SplitButton
                type={Button.inputTags.SUBMIT} // TODO: make this work for clicking enter?
                onClick={onCreateItem}
                loading={isCreatingItem} // TODO: Figure out UI when this is true
                marginLeft
              >
                Create Item
              </SplitButton>
            </Fragment>
          )}
        </Media>
      </div>
    </Fragment>
  );
};

export default UrlInput;
