import { useState } from 'react';
import UrlInput from '../UrlInput/UrlInput';
import css from './DashboardWidget.module.scss';
import mondaySdk from 'monday-sdk-js';
import Info from 'monday-ui-react-core/dist/icons/Info';
import RatingStar from './RatingStar';
import { Tooltip, Loader } from 'monday-ui-react-core';
import classNames from 'classnames';

const monday = mondaySdk();

export interface ProductDetails {
  name: string;
  images: string[];
  id: string;
  url?: string;
  description?: string;
  brand?: string;
  sku?: string;
  availability?: 'in_stock' | 'out_of_stock' | 'in_store_only' | 'online_only' | 'pre_order' | 'discontinued';
  price?: number;
  currency?: string;
  rating?: number;
}

interface ProductDetailsIconProps {
  productDetails: ProductDetails;
}

interface ProductAvailabilityProps {
  availability?: string
}

const ProductAvailability: React.FC<ProductAvailabilityProps> = ({ availability }) => {
  if (!availability) {
    return null;
  }

  let message;
  let availabilityType;

  switch (availability) {
    case 'in_stock':
      message = 'In Stock';
      availabilityType = 'positive';
      break;
    case 'in_store_only':
      message = 'In Store Only';
      availabilityType = 'positive';
      break;
    case 'online_only':
      message = 'Online Only';
      availabilityType = 'positive';
      break;
    case 'pre_order':
      message = 'Pre-order';
      break;
    case 'discontinued':
      message = 'Discontinued';
      availabilityType = 'negative';
      break;
    case 'out_of_stock':
      message = 'Out of Stock';
      availabilityType = 'negative';
      break;
    default:
      message = availability?.replaceAll('_', ' ');
      break;
  }

  return (
    <div className={classNames(
      css.ProductDetails__details__availability,
      css[`ProductDetails__details__availability--${availabilityType}`]
    )}>
      {message}
    </div>
  );
};

const ProductDetailsIcon: React.FC<ProductDetailsIconProps> = ({ productDetails, ...iconProps }) => {
  const { name, images, rating, availability, price, currency, description } = productDetails;

  return (
    <Tooltip
      referenceWrapperClassName={css.DashboardWidget__urlInputHolder__icon}
      theme="light"
      content={(
        <div className={css.ProductDetails}>
          {images?.length && (
            <img className={css.ProductDetails__thumbnail} src={images[0]} />
          )}
          <div className={css.ProductDetails__details}>
            <div
              className={classNames(css.ProductDetails__details__shortened, css.ProductDetails__details__name)}
              dangerouslySetInnerHTML={{ __html: name! }}
            />
            {/* TODO: Cover % of the orange icon rather than rounding */}
            {rating && [1, 2, 3, 4, 5].map(point => (
              <span
                key={`rating-star-${point}`}
                className={classNames(css.ProductDetails__details__ratingStar, {
                  [css['ProductDetails__details__ratingStar--active']]: Math.round(rating) >= point
                })}
              >
                <RatingStar size={13} />
              </span>
            ))}
            {description && (
              <div
                className={css.ProductDetails__details__shortened}
                dangerouslySetInnerHTML={{ __html: description! }} />
            )}
            <div className={css.ProductDetails__details__pricingAndAvailability}>
              {price && (
                price.toLocaleString(undefined, { style: 'currency', currency, minimumFractionDigits: 2, maximumFractionDigits: 2 })
              )}
              <ProductAvailability availability={availability} />
            </div>
          </div>
        </div>
      )}
    >
      <Info
        {...iconProps}
      />
    </Tooltip>
  );
};

const DashboardWidget = () => {
  const [isCreatingItem, setIsCreatingItem] = useState(false);
  const [productDetails, setProductDetails] = useState<ProductDetails | null>(null);
  const [isLoadingProductDetails, setIsLoadingProductDetails] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const createItem = async () => {
    setIsCreatingItem(true);

    // TODO: Actually create an item
    const itemId = 1181213368;
    await new Promise(resolve => {
      setTimeout(() => {
        resolve(null);
      }, 1000);
    });

    setIsCreatingItem(false);
    monday.execute('openItemCard', { itemId }); // TODO: Show button to open item instead of automatically opening it
  };

  // const getMondayToken = async () => {
  //   const response = await monday.get('sessionToken');

  //   return response.data as string;
  // };

  const loadProductDetails = async (url: string) => {
    const result = await fetch(`https://product-storm.bebapps.dev/api/product-details/products/from-url?url=${encodeURIComponent(url)}`);

    const body = await result.json();

    if (body.productDetails) {
      return body.productDetails as ProductDetails;
    }
  };

  const preloadThumbnail = (src: string) => {
    const image = new Image();
    image.src = src;
    // TODO: use onload to save image to add as attachment on item?
    // image.onload = (e: any) => console.log(123123, e.target.naturalWidth);
  };

  const onUrlInputChange = async (value: string, isValidUrl: boolean) => {
    setErrorMessage(undefined);
    if (isValidUrl) {
      setIsLoadingProductDetails(true);
      setProductDetails(null);
      const loadedProductDetails = await loadProductDetails(value);
      if (loadedProductDetails?.name) {
        setProductDetails(loadedProductDetails);
        preloadThumbnail(loadedProductDetails.images![0]);
      } else {
        setErrorMessage('Failed to load product. Please try another URL.');
      }
    } else {
      setProductDetails(null);
    }

    setIsLoadingProductDetails(false);
  };

  let icon;
  if (productDetails || isLoadingProductDetails) {
    // eslint-disable-next-line react/display-name
    icon = (props: any) => productDetails
      ? <ProductDetailsIcon productDetails={productDetails} {...props} />
      : <span className={css.DashboardWidget__urlInputHolder__loader} ><Loader {...props} /></span>;
  }

  return (
    <div className={css.DashboardWidget}>
      <div className={css.DashboardWidget__urlInputHolder}>
        <UrlInput
          errorMessage={errorMessage}
          onCreateItem={createItem}
          isCreatingItem={isCreatingItem}
          onChange={onUrlInputChange}
          icon={icon}
        // TODO: return error message to display (and be red) if loading product fails
        />
      </div>
    </div>
  );
};

export default DashboardWidget;
