import { fabric } from 'fabric';
import { memo, useContext } from 'react';
import CanvasContext from '../CanvasContext';
import FabricListener from '../FabricListener';

interface DrawingListenerProps {
  updateData: (path: string, value: any, commit?: boolean) => void;
}

const DrawingListener: React.FC<DrawingListenerProps> = ({ updateData }) => {
  const canvas = useContext(CanvasContext);

  const pathCreatedHandler = ({ path: created }: { path: fabric.Path }) => {
    const { path, top, left, stroke, strokeWidth } = created;

    canvas.remove(created);

    const id = Math.round((Math.random() * 1000000000));

    updateData(`drawings.${id}`, { path: JSON.stringify(path), top, left, stroke, strokeWidth });
  };

  return (
    <FabricListener
      eventname="path:created"
      handler={pathCreatedHandler}
    />
  );
};

export default memo(DrawingListener);
