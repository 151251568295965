import { fabric } from 'fabric';

export const createFabricShadowMedium = () => new fabric.Shadow({
  color: 'rgba(0, 0, 0, 0.2)',
  blur: 20,
  offsetY: 6
});

export const createFabricShadowSmall = () => new fabric.Shadow({
  color: 'rgba(0, 0, 0, 0.2)',
  blur: 8,
  offsetY: 4
});

export const BUTTON_COLORS: { [key: string]: { normal: string, hovered: string } } = {
  Primary: {
    normal: '#0085FF',
    hovered: '#0270D9'
  },
  Secondary: {
    normal: 'white',
    hovered: '#E6E9EF'
  }
};
