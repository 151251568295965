import { fabric } from 'fabric';
import { Fragment, memo } from 'react';
import { DrawingObject } from '../FabricDrawing';
import FabricListener from '../FabricListener';
import { PhotoObject } from '../FabricPhoto/FabricPhoto';
import { TextObject } from '../FabricText';
import DrawingListener from './DrawingListener';

const ObjectModifiedListener: React.FC<{ updateData: (path: string, value: any, commit: boolean) => void }> = ({ updateData }) => {
  const objectModifiedHandler = ({ target }: fabric.IEvent) => {
    // TODO: add support for DrawingObject.
    // TODO: extract update calls done for each type (after making pvId a common property between them)
    if (target instanceof PhotoObject) {
      const { angle, left, top, scaleX: scale } = target!;

      const pathPrefix = `images.${target.pvItemId}`;
      updateData(`${pathPrefix}.left`, Math.round(left!), true);
      updateData(`${pathPrefix}.top`, Math.round(top!), true);
      updateData(`${pathPrefix}.angle`, Math.round(angle! * 10000) / 10000, true);
      updateData(`${pathPrefix}.scale`, Math.round(scale! * 10000) / 10000, true);

      return;
    }

    if (target instanceof TextObject) {
      const { width, left, top, text, angle } = target!;

      const pathPrefix = `texts.${target.pvId}`;
      updateData(`${pathPrefix}.left`, Math.round(left!), true);
      updateData(`${pathPrefix}.top`, Math.round(top!), true);
      updateData(`${pathPrefix}.width`, Math.round(width! * 10000) / 10000, true);
      updateData(`${pathPrefix}.text`, text, true);
      updateData(`${pathPrefix}.angle`, Math.round(angle! * 10000) / 10000, true);
    }

    if (target instanceof DrawingObject) {
      const { left, path, stroke, strokeWidth, top, angle } = target!;

      const pathPrefix = `drawings.${target.pvDrawingId}`;
      updateData(`${pathPrefix}.left`, Math.round(left!), true);
      updateData(`${pathPrefix}.top`, Math.round(top!), true);
      updateData(`${pathPrefix}.path`, path, true);
      updateData(`${pathPrefix}.stroke`, stroke, true);
      updateData(`${pathPrefix}.strokeWidth`, strokeWidth, true);
      updateData(`${pathPrefix}.angle`, Math.round(angle! * 10000) / 10000, true);
    }
  };

  const textChangedHandler = ({ target }: { target: fabric.Object }) => {
    if (!(target instanceof TextObject)) {
      return;
    }

    updateData(`texts.${target.pvId}.text`, target.text, true);
  };

  return (
    <Fragment>
      <FabricListener
        eventname="object:modified"
        handler={objectModifiedHandler}
      />
      <FabricListener
        eventname="text:changed"
        handler={textChangedHandler}
      />
    </Fragment>
  );
};

export default memo(ObjectModifiedListener);
