import css from './Tools.module.scss';
import { useRef } from 'react';
import DialogTool from './DialogTool';
import { DialogContentContainer } from 'monday-ui-react-core';
import classNames from 'classnames';

interface ColorPickerProps {
  currentColor: string;
  remoteColor?: string;
  options?: string[];
  setColor: (color: string) => void;
  setLocalColor: (color: string) => void;
  IconComponent: any;
  theme?: string;
}

const defaultcolors = ['#0085FF', '#323338', '#676879', '#E6E9EF', '', '#FF7575', '#FDAB3D', '#FFCB00', '#00C875', '#5559DF', '#FAA1F1'];

const ColorPicker: React.FC<ColorPickerProps> = ({ options = defaultcolors, currentColor, remoteColor, setColor, setLocalColor, IconComponent, theme }) => {
  const revertStrokeTimeoutRef = useRef<any>();

  const onMouseEnterStrokeColor = (color: string) => {
    clearTimeout(revertStrokeTimeoutRef.current);
    setLocalColor(color);
  };

  const onMouseLeaveStrokeColor = () => {
    revertStrokeTimeoutRef.current = setTimeout(() => {
      if (remoteColor) {
        setLocalColor(remoteColor);
      }
    }, 400);
  };

  const onSelectStrokeColor = (color: string) => {
    clearTimeout(revertStrokeTimeoutRef.current);
    setColor(color);
  };
  return (
    <DialogTool
      IconComponent={IconComponent}
      toolIconStyle={{ color: currentColor }}
      toolIconbackgroundColor={currentColor?.match(/#[0-9a-f]{6}/i)
        ? `${currentColor}22`
        : currentColor?.match(/rgba/i)
          ? currentColor.replace(/,[^,]*\)$/i, ',0.133)')
          : getComputedStyle(document.documentElement).getPropertyValue('--primary-background-hover-on-secondary-color') || '#e6e9ef' // TODO: check element that actually respects dark mode
      }
      hideTooltipOnOpen
      position='bottom'
      tooltip="Color"
    >
      <DialogContentContainer className={classNames(css.InnerOptions, css['InnerOptions--horizontal'], { 'dark-app-theme': theme === 'dark' })}>
        {options.map((color, index) => (
          color.length
            ? <button
              key={color}
              className={css.Color}
              style={{ backgroundColor: color }}
              onMouseEnter={() => onMouseEnterStrokeColor(color)}
              onClick={() => onSelectStrokeColor(color)}
              onMouseLeave={onMouseLeaveStrokeColor}
            />
            : <span key={`spacer-${index}`} className={classNames(css.Tools__divider, css['Tools__divider--vertical'])} />
        ))}
      </DialogContentContainer>
    </DialogTool>
  );
};

export default ColorPicker;
