import { fabric } from 'fabric';
import { useContext, useEffect, memo, useRef } from 'react';
import CanvasContext from '../CanvasContext';
import { createFabricShadowSmall } from '../constants';
import { ensureObjectIsOnScreen } from '../helpers';

interface FabricUserProps {
  top: number;
  left: number;
  name: string;
  color: string;
}

const getNameBackgroundObject = (user: fabric.Group) => user.item(0) as unknown as fabric.Rect;
const getCursorObject = (user: fabric.Group) => user.item(2) as unknown as fabric.Path;

const FabricUser: React.FC<FabricUserProps> = ({ top, left, name, color }) => {
  const canvas = useContext(CanvasContext);
  const userRef = useRef<fabric.Group>();

  const applyChanges = (user: fabric.Group) => {
    user.set({
      top,
      left
    });

    user.bringToFront(); // TODO: check if this is performant / needs to be debounced

    getNameBackgroundObject(user).set('backgroundColor', color);
    getCursorObject(user).set('fill', color);
    // todo: update name

    // TODO: check if this is necessary for users
    ensureObjectIsOnScreen(user);

    canvas.requestRenderAll();
  };

  if (userRef.current) {
    applyChanges(userRef.current);
  }

  useEffect(() => {
    const nameText = new fabric.Text(name, {
      fontFamily: 'Roboto,sans-serif',
      fontSize: 14,
      fill: 'white',
      left: 24,
      top: 12
    });

    const nameBox = new fabric.Rect({
      height: 20,
      width: nameText.width! + 8,
      left: 20,
      top: 10,
      fill: color,
      stroke: 'white'
    });

    const cursor = new fabric.Path('M3 3l7.07 16.97 2.51-7.39 7.39-2.51L3 3z'); // https://feathericons.com/?query=mouse-pointer

    cursor.set({
      scaleX: 1,
      scaleY: 1,
      top: 0,
      left: -1,
      fill: color,
      shadow: createFabricShadowSmall(),
      stroke: 'white'
    });

    const user = new fabric.Group([nameBox, nameText, cursor], {
      scaleX: 1,
      scaleY: 1,
      top,
      left,
      selectable: false,
      hoverCursor: 'default'
    });

    userRef.current = user;

    canvas.add(user);

    const scaleForZoom = () => {
      const scale = 1 / canvas.getZoom();
      userRef.current?.set({
        scaleX: scale,
        scaleY: scale
      });
    };

    canvas.on('before:render', scaleForZoom);

    return () => {
      canvas.remove(userRef.current!);
      canvas.off('before:render', scaleForZoom);
    };
  }, []);

  return null;
};

export default memo(FabricUser);
