import set from 'lodash.set';
import unset from 'lodash.unset';
import { useEffect, useRef, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom'; // eslint-disable-line

// start: message types
interface BaseMessage {
  ts: number;
}

/* eslint-disable */
export enum MessageType {
  UPDATE_PROFILE,
  UPDATING_DATA,
  UPDATE_DATA,
  WELCOME,
  PEER_CONNECTED,
  PEER_PROFILE_UPDATED,
  PEER_DISCONNECTED,
  ERROR_OCCURRED,
  DATA_UPDATING,
  DATA_UPDATED,
  PING,
  PONG,
}

export enum DataUpdateOperation {
  set,
  delete,
}
/* eslint-enable */

export interface UpdateProfileMessage extends BaseMessage {
  t: MessageType.UPDATE_PROFILE; // type
  u: object; // updates
}

export interface UpdatingDataMessage extends BaseMessage {
  t: MessageType.UPDATING_DATA; // type
  p: string; // path
  o: DataUpdateOperation.set | DataUpdateOperation.delete; // operation
  v: any; // value
}

export interface UpdateDataMessage extends BaseMessage {
  t: MessageType.UPDATE_DATA; // type
  p: string; // path
  o: DataUpdateOperation.set | DataUpdateOperation.delete; // operation
  v: any; // value
}

export interface PingMessage extends BaseMessage {
  t: MessageType.PING; // type
}

export interface WelcomeMessage extends BaseMessage {
  t: MessageType.WELCOME; // type
  s: { // self
    i: string; // id
    p: object; // profile
  };
  p: { // peers
    i: string; // id
    p: object; // profile
  }[];
  d: object; // data
  tso: number; // timestampOffset
}

export interface PeerConnectedMessage extends BaseMessage {
  t: MessageType.PEER_CONNECTED; // type
  i: string; // id
  p: object; // profile
}

export interface PeerProfileUpdatedMessage extends BaseMessage {
  t: MessageType.PEER_PROFILE_UPDATED; // type
  i: string; // id
  u: object; // profile
}

export interface PeerDisconnectedMessage extends BaseMessage {
  t: MessageType.PEER_DISCONNECTED; // type
  i: string; // id
}

// only in development mode
export interface ErrorOccurredMessage extends BaseMessage {
  t: MessageType.ERROR_OCCURRED; // type
  m: string; // message
}

export interface DataUpdatingMessage extends BaseMessage {
  t: MessageType.DATA_UPDATING; // type
  p: string; // path
  o: DataUpdateOperation.set | DataUpdateOperation.delete; // operation
  v: any; // value
  s?: string; // senderId
}

export interface DataUpdatedMessage extends BaseMessage {
  t: MessageType.DATA_UPDATED; // type
  p: string; // path
  o: DataUpdateOperation.set | DataUpdateOperation.delete; // operation
  v: any; // value
  s?: string; // senderId
}

export interface PongMessage extends BaseMessage {
  t: MessageType.PING; // type
}

export type IncomingMessage =
  | UpdateProfileMessage
  | UpdatingDataMessage
  | UpdateDataMessage
  | PingMessage

export type OutgoingMessage =
  | WelcomeMessage
  | PeerConnectedMessage
  | PeerProfileUpdatedMessage
  | PeerDisconnectedMessage
  | ErrorOccurredMessage
  | DataUpdatingMessage
  | DataUpdatedMessage
  | PingMessage
// end: message types

export interface Peer<Profile> {
  id: string;
  profile: Partial<Profile>;
}

type PeerMap<Profile> = Record<string, Peer<Profile>>;

export interface VisionBoardOptions {
  id: string;
  applyPendingUpdatesLocally?: boolean;
  artificialDelay?: number;
  token: string;
}

export const useVisionBoard = <Data = object, Profile = object>(options: VisionBoardOptions) => {
  const socketRef = useRef<WebSocket>();

  const timestampOffsetRef = useRef<number>(Date.now());
  const nowTimestamp = () => Date.now() - timestampOffsetRef.current!;

  const incomingMessageQueueRef = useRef<OutgoingMessage[]>();
  if (!incomingMessageQueueRef.current) incomingMessageQueueRef.current = [];

  const outgoingMessageQueueRef = useRef<IncomingMessage[]>();
  if (!outgoingMessageQueueRef.current) outgoingMessageQueueRef.current = [];

  const messageQueueTimeoutRef = useRef<number>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [self, setSelf] = useState<Peer<Profile>>();
  const [data, setData] = useState<Data>({} as any);
  const [peers, setPeers] = useState<PeerMap<Profile>>({} as any);

  const scheduleOutgoingMessageQueue = () => {
    if (!messageQueueTimeoutRef.current) {
      messageQueueTimeoutRef.current = window.setTimeout(() => {
        messageQueueTimeoutRef.current = undefined;
        if (outgoingMessageQueueRef.current?.length && socketRef.current?.readyState === WebSocket.OPEN && timestampOffsetRef.current && !isLoading) {
          const messages = outgoingMessageQueueRef.current;
          const payload = JSON.stringify(messages.length > 1 ? messages : messages[0]);
          outgoingMessageQueueRef.current.length = 0;
          socketRef.current.send(payload);
        }
      }, 20);
    }
  };

  const sendMessage = (message: IncomingMessage | IncomingMessage[]) => {
    const messages = ([] as IncomingMessage[]).concat(message);
    outgoingMessageQueueRef.current!.push(...messages);
    scheduleOutgoingMessageQueue();
  };

  const onPeerProfileUpdated = (message: PeerProfileUpdatedMessage) => {
    setPeers((peers) => {
      const peerId = message.i;
      const existingPeer = peers[peerId] || {};
      return {
        ...peers,
        [peerId]: {
          ...existingPeer,
          profile: {
            ...existingPeer.profile,
            ...message.u
          }
        }
      };
    });
  };

  const onDataUpdates = (message: DataUpdatingMessage | DataUpdatedMessage) => {
    setData((data) => {
      const newData = { ...data };
      switch (message.o) {
        case DataUpdateOperation.set: {
          set(newData as any, message.p, message.v);
          break;
        }
        case DataUpdateOperation.delete: {
          unset(newData as any, message.p);
          break;
        }
      }
      return newData;
    });
  };

  const updateProfile = (updates: Partial<Profile>) => {
    sendMessage({
      t: MessageType.UPDATE_PROFILE,
      u: updates,
      ts: nowTimestamp()
    });
    setSelf((self) => {
      return {
        ...self!,
        profile: {
          ...self!.profile,
          ...updates
        }
      };
    });
  };

  const updateData = (path: string, value: any, commit: boolean = true) => {
    const operation = typeof value === 'undefined'
      ? DataUpdateOperation.delete
      : DataUpdateOperation.set;

    sendMessage({
      t: commit ? MessageType.UPDATE_DATA : MessageType.UPDATING_DATA,
      p: path,
      o: operation,
      v: value,
      ts: nowTimestamp()
    });

    if (options.applyPendingUpdatesLocally || commit) {
      onDataUpdates({
        t: MessageType.DATA_UPDATED,
        p: path,
        o: operation,
        v: value,
        ts: -1
      });
    }
  };

  const dynamicDelay = useRef<{[peerId: string]: number}>({});

  const wipeDynamicDelayTimout = useRef<any>();
  const wipeDynamicDelay = () => {
    dynamicDelay.current = {};
    wipeDynamicDelayTimout.current = setTimeout(() => {
      wipeDynamicDelay();
    }, 10000);
  };

  const lastRAFTimestamp = useRef<any>();
  const bypassRAFTimout = useRef<any>();
  const bypassRAF = () => {
    if ((Date.now() - lastRAFTimestamp.current) > 200) {
      processIncomingMessages();
    }
    bypassRAFTimout.current = setTimeout(() => {
      bypassRAF();
    }, 200);
  };

  useEffect(() => {
    wipeDynamicDelay();
    bypassRAF();

    return () => {
      clearTimeout(wipeDynamicDelayTimout.current);
      clearTimeout(bypassRAFTimout.current);
    };
  }, []);

  const processIncomingMessages = () => {
    const messages: OutgoingMessage[] = [];
    incomingMessageQueueRef.current = incomingMessageQueueRef.current!.filter((message) => {
      const senderId: string | undefined = message.t === MessageType.PEER_PROFILE_UPDATED
        ? message.i
        : (message as any).s;
      if (senderId) {
        if (dynamicDelay.current[senderId]) {
          if ((dynamicDelay.current[senderId] + message.ts) > Date.now()) {
            return true;
          }
        } else {
          dynamicDelay.current[senderId] = (Date.now() - message.ts) + 100;
          return true;
        }
      }

      messages.push(message);
      return false;
    });

    messages.sort((a, b) => (a.ts > b.ts) ? 1 : -1);

    // if (incomingMessageQueueRef.current.length) {
    //   console.log('queued messages', incomingMessageQueueRef.current.length);
    // }

    unstable_batchedUpdates(() => {
      for (const message of messages) {
        switch (message.t) {
          case MessageType.WELCOME: {
            setSelf({ id: message.s.i, profile: message.s.p });
            setIsLoading(false);
            setData(message.d as unknown as Data);
            setPeers(message.p.reduce((peerMap, peer) => ({ ...peerMap, [peer.i]: { id: peer.i, profile: peer.p } }), {} as PeerMap<Profile>));
            scheduleOutgoingMessageQueue();
            break;
          }
          case MessageType.PEER_PROFILE_UPDATED: {
            onPeerProfileUpdated(message);
            break;
          }
          case MessageType.PEER_CONNECTED: {
            setPeers((peers) => ({
              ...peers,
              [message.i]: {
                id: message.i,
                profile: message.p
              }
            }));
            break;
          }
          case MessageType.PEER_DISCONNECTED: {
            setPeers((peers) => {
              const newPeers = { ...peers };
              delete newPeers[message.i];
              return newPeers;
            });
            break;
          }
          case MessageType.DATA_UPDATED:
          case MessageType.DATA_UPDATING: {
            onDataUpdates(message);
            break;
          }
        }
      }
    });
  };

  const connect = (visionBoardId: string, token: string) => {
    const host = window.location.host;
    const websocketHost = host === 'localhost:3000' ? 'product-vision.bebapps.dev' : host;

    const socket = new WebSocket(`wss://${websocketHost}/api/vision-boards/${visionBoardId}/connect?token=${token}`);

    socket.addEventListener('message', (event: MessageEvent<string>) => {
      const messages = ([] as OutgoingMessage[]).concat(JSON.parse(event.data));
      incomingMessageQueueRef.current!.push(...messages);
    });

    return socket;
  };

  useEffect(() => {
    let socket: WebSocket;

    function setup () {
      socketRef.current?.close();
      socket = connect(options.id, options.token);
      socketRef.current = socket;
      socket.addEventListener('close', setup);
    }
    setup();

    return () => {
      socket.removeEventListener('close', () => {
        setTimeout(setup, 1000);
      });
      socket.close();
      clearTimeout(messageQueueTimeoutRef.current);
    };
  }, [options.id]);

  useEffect(() => {
    let raf: number = requestAnimationFrame(function next () {
      lastRAFTimestamp.current = Date.now();
      processIncomingMessages();
      raf = requestAnimationFrame(next);
    });

    return () => {
      cancelAnimationFrame(raf);
    };
  }, []);

  return {
    isLoading,
    data,
    updateData,
    profile: self ? self.profile : null,
    updateProfile,
    peers: Object.values(peers)
  };
};
