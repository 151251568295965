import { fabric } from 'fabric';
import FabricListener from '../FabricListener';
import { useRef, Fragment, useContext, memo } from 'react';
import CanvasContext from '../CanvasContext';
import { PhotoObject } from '../FabricPhoto/FabricPhoto';

const PhotoListener = () => {
  const canvas = useContext(CanvasContext);
  const controlsRef = useRef<{ [name: string]: fabric.Group }>({});

  const animationOptions = {
    easing: fabric.util.ease.easeInOutCubic,
    onChange: canvas.renderAll.bind(canvas),
    duration: 150
  };

  const showControls = (photo: fabric.Object) => {
    const photoControls = (photo as fabric.Group).item(2);
    photoControls.animate('opacity', 1, animationOptions);

    controlsRef.current[photo.name!] = photoControls;
  };

  const hideContols = (photo: fabric.Object) => {
    controlsRef.current[photo.name!]?.animate('opacity', 0, animationOptions);
    delete controlsRef.current[photo.name!];
  };

  return (
    <Fragment>
      <FabricListener
        eventname="mouse:over"
        handler={({ target }: fabric.IEvent) => {
          if (target instanceof PhotoObject) {
            showControls(target!);
          }
        }}
      />
      <FabricListener
        eventname="mouse:out"
        handler={({ target }: fabric.IEvent) => {
          if (target instanceof PhotoObject) {
            hideContols(target!);
          }
        }}
      />
    </Fragment>
  );
};

export default memo(PhotoListener);
