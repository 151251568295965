import { fabric } from 'fabric';
import FabricListener from '../FabricListener';
import { BUTTON_COLORS } from '../constants';
import { useRef, Fragment, useContext, memo } from 'react';
import CanvasContext from '../CanvasContext';
import { PhotoObject } from '../FabricPhoto/FabricPhoto';
import { Profile } from '../../VisionBoard/VisionBoard';
import { TextObject } from '../FabricText';
import { useMondaySdk } from '../../../hooks/useMondaySdk';

// TODO: replace use of name with use of custom classes
const getValueFromName = (key: string, name?: string) => name?.match(RegExp(`${key}:(\\w*)`))?.[1]; // TODO: use already created RegExps for performance?

const isButton = (object?: fabric.Object) => getValueFromName('Type', object?.name) === 'Button';

interface ButtonListenerProps {
  updateProfile: (updates: Partial<Profile>) => void;
  editingTextId: string | undefined;
}

const ButtonListener: React.FC<ButtonListenerProps> = ({ updateProfile, editingTextId }) => {
  const canvas = useContext(CanvasContext);
  const mouseDownedButtonRef = useRef<fabric.Group | null>();
  const hoveredButtonRef = useRef<fabric.Group | null>();
  const monday = useMondaySdk();

  const animationOptions = {
    easing: fabric.util.ease.easeInOutCubic,
    onChange: canvas.renderAll.bind(canvas),
    duration: 150
  };

  const mouseDownHandler = ({ transform, subTargets }: fabric.IEvent) => {
    const button = subTargets?.find(isButton);
    if (button) {
      button!.animate({
        scaleX: 0.96,
        scaleY: 0.96
      }, animationOptions);

      mouseDownedButtonRef.current = button as fabric.Group;
    }

    // Fabric types seem to be wrong here
    const transformTarget = (transform as any)?.target as fabric.Object;

    if (transformTarget instanceof PhotoObject) {
      updateProfile({
        targetId: transformTarget.pvItemId
      });
    }
  };

  const mouseMoveHandler = ({ subTargets }: fabric.IEvent) => {
    const button = subTargets?.find(isButton) as fabric.Group | undefined;
    const hoveredButton = hoveredButtonRef.current;
    const isCurrent = button?.name === hoveredButton?.name;

    if (isCurrent) {
      return;
    }

    if (!isCurrent && hoveredButton) {
      const normalColor = BUTTON_COLORS[getValueFromName('Style', hoveredButton?.name)!].normal;
      hoveredButton.item(0).set('fill', normalColor);
      canvas.renderAll();
    }

    if (button) {
      const hoveredColor = BUTTON_COLORS[getValueFromName('Style', button.name)!].hovered;
      button.item(0).set('fill', hoveredColor);
      canvas.requestRenderAll();
    }

    hoveredButtonRef.current = button;
  };

  const mouseUpHandler = ({ target }: fabric.IEvent) => {
    const button = mouseDownedButtonRef.current;
    if (button) {
      button?.animate({
        scaleX: 1,
        scaleY: 1
      }, animationOptions);

      mouseDownedButtonRef.current = null;

      if (target instanceof PhotoObject && target.pvMondayItemId) {
        monday.execute('openItemCard', { itemId: target.pvMondayItemId });
        canvas.discardActiveObject();
      }
    }

    // Clearing of targetId after editing text is handled by TextListener
    // MouseUpHandler cannot handle this as users can stay focused when moving around inside a textbox
    if (!editingTextId || !(target instanceof TextObject) || target.pvId !== editingTextId) {
      updateProfile({
        targetId: null
      });
    }
  };

  return (
    <Fragment>
      <FabricListener
        eventname="mouse:down"
        handler={mouseDownHandler}
      />
      <FabricListener
        eventname="mouse:move:before"
        handler={mouseMoveHandler}
      />
      <FabricListener
        eventname="mouse:up"
        handler={mouseUpHandler}
      />
    </Fragment>
  );
};

export default memo(ButtonListener);
