import css from './Tools.module.scss';
import Textcolor from 'monday-ui-react-core/dist/icons/Textcolor';
import Bold from 'monday-ui-react-core/dist/icons/Bold';
import Italic from 'monday-ui-react-core/dist/icons/Italic';
import Text from 'monday-ui-react-core/dist/icons/Text';
import TextBig from 'monday-ui-react-core/dist/icons/TextBig';
import TextHuge from 'monday-ui-react-core/dist/icons/TextHuge';
import { Fragment, memo, useMemo } from 'react';
import Tool from './Tool';
import DialogTool from './DialogTool';
import { DialogContentContainer } from 'monday-ui-react-core';
import TextAlignCenter from './TextAlignCenter';
import TextAlignRight from './TextAlignRight';
import TextAlignLeft from './TextAlignLeft';
import ColorPicker from './ColorPicker';
import classNames from 'classnames';

export interface TextToolSettings {
  fontStyle?: '' | 'italic' | 'normal' | 'oblique';
  fontWeight?: string | number;
  textAlign?: string;
  fontSize?: number; // TODO: add support for this
  // fontFamily?: string; // TODO: add support for this
  // textBackgroundColor?: string; // TODO: add support for this maybe
  fill?: any;
}

interface TextToolsProps extends TextToolSettings {
  updateTextTool: (key: keyof TextToolSettings, value: boolean | string | number, localOnly?: boolean) => void;
  remoteFill?: string;
  theme?: string;
}

const TextTools: React.FC<TextToolsProps> = ({ fontStyle, fontWeight, updateTextTool, textAlign, fill, remoteFill, theme, fontSize }) => {
  const isBold = fontWeight === 'bold';
  const isItalic = fontStyle === 'italic';

  const AlignmentIcon = useMemo(() => {
    switch (textAlign) {
      case 'center':
        return TextAlignCenter;
      case 'right':
        return TextAlignRight;
      case 'left':
      default:
        return TextAlignLeft;
    }
  }, [textAlign]);

  const SizeIcon = useMemo(() => {
    switch (fontSize) {
      case 32:
        return TextHuge;
      case 16:
        return Text;
      case 24:
      default:
        return TextBig;
    }
  }, [fontSize]);

  return (
    <Fragment>
      <Tool
        IconComponent={Bold}
        isActive={isBold}
        onClick={() => updateTextTool('fontWeight', isBold ? 'normal' : 'bold')}
        tooltip="Bold"
      />
      <Tool
        IconComponent={Italic}
        isActive={isItalic}
        onClick={() => updateTextTool('fontStyle', isItalic ? 'normal' : 'italic')}
        tooltip="Italic"
      />
      <DialogTool
        IconComponent={AlignmentIcon}
        hideTooltipOnOpen
        position='bottom'
        tooltip="Alignment"
      >
        <DialogContentContainer size='' className={classNames(css.InnerOptions, css['InnerOptions--horizontal'], { 'dark-app-theme': theme === 'dark' })}>
          <Tool
            IconComponent={TextAlignLeft}
            isActive={!textAlign || textAlign === 'left'}
            onClick={() => updateTextTool('textAlign', 'left')}
            tooltip="Left"
          />
          <Tool
            IconComponent={TextAlignCenter}
            isActive={textAlign === 'center'}
            onClick={() => updateTextTool('textAlign', 'center')}
            tooltip="Center"
          />
          <Tool
            IconComponent={TextAlignRight}
            isActive={textAlign === 'right'}
            onClick={() => updateTextTool('textAlign', 'right')}
            tooltip="Right"
          />
        </DialogContentContainer>
      </DialogTool>
      <DialogTool
        IconComponent={SizeIcon}
        hideTooltipOnOpen
        position='bottom'
        tooltip="Size"
      >
        <DialogContentContainer size='' className={classNames(css.InnerOptions, css['InnerOptions--horizontal'], { 'dark-app-theme': theme === 'dark' })}>
          <Tool
            IconComponent={Text}
            isActive={fontSize === 16}
            onClick={() => updateTextTool('fontSize', 16)}
            tooltip="Small"
          />
          <Tool
            IconComponent={TextBig}
            isActive={fontSize === 24}
            onClick={() => updateTextTool('fontSize', 24)}
            tooltip="Medium"
          />
          <Tool
            IconComponent={TextHuge}
            isActive={fontSize === 32}
            onClick={() => updateTextTool('fontSize', 32)}
            tooltip="Large"
          />
        </DialogContentContainer>
      </DialogTool>

      <ColorPicker
        IconComponent={Textcolor}
        currentColor={fill}
        setColor={color => updateTextTool('fill', color)}
        setLocalColor={color => updateTextTool('fill', color, true)}
        remoteColor={remoteFill}
        theme={theme}
      />
    </Fragment>
  );
};

export default memo(TextTools);
