import css from './Tools.module.scss';
import Edit from 'monday-ui-react-core/dist/icons/Edit';
import Info from 'monday-ui-react-core/dist/icons/Info';
import TextBig from 'monday-ui-react-core/dist/icons/TextBig';
import Delete from 'monday-ui-react-core/dist/icons/Delete';
import Print from 'monday-ui-react-core/dist/icons/Print';
import { DialogContentContainer, Divider } from 'monday-ui-react-core';
import { memo, useContext } from 'react';
import Tool from './Tool';
import Zoom from './Zoom';
import Panning from './Panning';
import DialogTool from './DialogTool';
import classnames from 'classnames';
import CanvasContext from '../../Fabric/CanvasContext';

interface ToolsProps {
  setIsDrawingMode: (isDrawingMode: boolean) => void;
  isDrawingMode: boolean;
  setIsTextMode: (isTextMode: boolean) => void;
  isTextMode: boolean;
  theme?: string;
}

const Tools: React.FC<ToolsProps> = ({ setIsDrawingMode, isDrawingMode, setIsTextMode, isTextMode, theme }) => {
  const canvas = useContext(CanvasContext);

  return (
    <div className={classnames(css.Wrapper, css['Wrapper--left'])}>
      <div className={classnames(css.Tools, css['Tools--left'])}>
        <Tool
          IconComponent={Edit}
          isActive={isDrawingMode}
          onClick={() => {
            canvas.discardActiveObject();
            if (isDrawingMode) {
              setIsDrawingMode(false);
            } else {
              setIsDrawingMode(true);
              setIsTextMode(false);
            }
          }}
          tooltip="Drawing mode"
        />
        <Tool
          IconComponent={TextBig}
          isActive={isTextMode}
          onClick={() => {
            canvas.discardActiveObject();
            if (isTextMode) {
              setIsTextMode(false);
            } else {
              setIsTextMode(true);
              setIsDrawingMode(false);
            }
          }}
          tooltip="Text mode"
        />
        <div className={css.Tools__divider} />
        <Tool
          IconComponent={Print}
          onClick={() => {
            const download = document.createElement('a');
            download.href = canvas.toDataURL({ format: 'png', multiplier: 2 });
            download.download = 'Vision Board.png'; // TODO: name based on board name
            download.click();
          }}
          tooltip="Save to PNG"
        />
        <DialogTool
          hideTooltipOnOpen
          position="right"
          tooltip="Help"
          IconComponent={Info}
          startingEdge="right"
          onOpen={() => {
            setIsDrawingMode(false);
            setIsTextMode(false);
          }}
        >
          <DialogContentContainer className={classnames(css.InnerOptions, css.InfoBox, { 'dark-app-theme': theme === 'dark' })}>
            <div className={css.InfoBox__message}>
              Add links to the products you want on items in your board (link or text columns). Images from the product pages will appear here.
            </div>
            <Divider />
            <div className={css.InfoBox__iconRow}>
              <Delete className={css.InfoBox__iconRow__icon} />
              <div>
                Delete text or drawings by selecting them and clicking ‘backspace’ or ‘delete’
              </div>
            </div>
            <div className={css.InfoBox__iconRow}>
              <Zoom className={css.InfoBox__iconRow__icon} />
              <div>
                Zoom in and out by scrolling with your mouse or pinching with your trackpad
              </div>
            </div>
            <div className={css.InfoBox__iconRow}>
              <Panning className={css.InfoBox__iconRow__icon} />
              <div>
                Pan by dragging empty space or dragging anywhere with the right mouse button
              </div>
            </div>
          </DialogContentContainer>
        </DialogTool>
      </div>
    </div>
  );
};

export default memo(Tools);
