import { fabric } from 'fabric';
import { Fragment, memo } from 'react';
import { DrawingObject } from '../FabricDrawing';
import FabricListener from '../FabricListener';
import { PhotoObject } from '../FabricPhoto/FabricPhoto';
import { TextObject } from '../FabricText';

const getPathPrefix = (target?: fabric.Object) => {
  if (target instanceof PhotoObject) {
    return `images.${target.pvItemId}`;
  }

  if (target instanceof DrawingObject) {
    return `drawings.${target.pvDrawingId}`;
  }

  if (target instanceof TextObject) {
    return `texts.${target.pvId}`;
  }
};

const ObjectChangingListener: React.FC<{ updateData: (path: string, value: any, commit: boolean) => void }> = ({ updateData }) => {
  const objectRotatingHandler = ({ target }: fabric.IEvent) => {
    const pathPrefix = getPathPrefix(target);

    if (pathPrefix) {
      updateData(`${pathPrefix}.angle`, Math.round(target!.angle! * 10000) / 10000, false);
    }
  };

  const objectMovingHandler = ({ target }: fabric.IEvent) => {
    const pathPrefix = getPathPrefix(target);

    if (pathPrefix) {
      updateData(`${pathPrefix}.top`, Math.round(target!.top!), false);
      updateData(`${pathPrefix}.left`, Math.round(target!.left!), false);
    }
  };

  const objectScalingHandler = ({ target }: fabric.IEvent) => {
    const pathPrefix = getPathPrefix(target);

    if (pathPrefix) {
      updateData(`${pathPrefix}.scale`, Math.round(target!.scaleX! * 10000) / 10000, false);
    }
  };

  const objectResizingHandler = ({ target }: fabric.IEvent) => {
    if (!(target instanceof TextObject)) {
      return; // This event is only used for adjusting the width of textboxes
    }

    updateData(`${getPathPrefix(target)}.width`, Math.round(target!.width! * 10000) / 10000, false);
    updateData(`${getPathPrefix(target)}.left`, Math.round(target!.left! * 10000) / 10000, false); // TODO: fix origin bug so this isn't needed (as this is jittery)
    updateData(`${getPathPrefix(target)}.top`, Math.round(target!.top! * 10000) / 10000, false); // TODO: fix origin bug so this isn't needed (as this is jittery)
  };

  return (
    <Fragment>
      <FabricListener
        eventname="object:rotating"
        handler={objectRotatingHandler}
      />
      <FabricListener
        eventname="object:moving"
        handler={objectMovingHandler}
      />
      <FabricListener
        eventname="object:scaling"
        handler={objectScalingHandler}
      />
      <FabricListener
        eventname="object:resizing"
        handler={objectResizingHandler}
      />
    </Fragment>
  );
};

export default memo(ObjectChangingListener);
