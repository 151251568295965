import { fabric } from 'fabric';
import { Fragment, memo, useContext, useEffect, useRef, useState } from 'react';
import CanvasContext from '../CanvasContext';
import FabricListener from '../FabricListener';

interface ZoomListenerProps {
  isDrawingMode?: boolean;
}

const ZoomListener: React.FC<ZoomListenerProps> = ({ isDrawingMode }) => {
  const canvas = useContext(CanvasContext);
  const [isDragging, setIsDragging] = useState(false);
  const lastPos = useRef({ x: 0, y: 0 }); // This appears to pan smoother than useState for each

  const mouseWheelHandler = ({ e }: any) => {
    const delta = e.deltaY;
    let zoom = canvas.getZoom();
    zoom *= (e.ctrlKey ? 0.995 : 0.999) ** delta;
    if (zoom > 20) zoom = 20;
    if (zoom < 0.01) zoom = 0.01;
    canvas.zoomToPoint(new fabric.Point(e.x, e.y), zoom);
    e.preventDefault();
    e.stopPropagation();
  };

  const mouseDownHandler = ({ e: evt, target }: any) => {
    if (evt.altKey === true ||
      [2, 3].includes(evt.which) ||
      (!target && evt.which === 1 && !isDrawingMode)) {
      setIsDragging(true);
      lastPos.current.x = evt.clientX;
      lastPos.current.y = evt.clientY;
    }
  };

  const mouseMoveHandler = (opt: any) => {
    if (isDragging) {
      const e = opt.e;
      canvas.viewportTransform![4] += e.clientX - lastPos.current.x;
      canvas.viewportTransform![5] += e.clientY - lastPos.current.y;
      canvas.requestRenderAll();
      lastPos.current.x = e.clientX;
      lastPos.current.y = e.clientY;
    }
  };

  const mouseUpHandler = (opt: any) => {
    canvas.setViewportTransform(canvas.viewportTransform!);
    setIsDragging(false);
  };

  useEffect(() => {
    canvas.defaultCursor = isDragging ? 'grabbing' : 'grab';
  }, [isDragging]);

  return (
    <Fragment>
      <FabricListener
        eventname="mouse:wheel"
        handler={mouseWheelHandler}
      />
      <FabricListener
        eventname="mouse:down"
        handler={mouseDownHandler}
      />
      <FabricListener
        eventname="mouse:move"
        handler={mouseMoveHandler}
      />
      <FabricListener
        eventname="mouse:up"
        handler={mouseUpHandler}
      />
    </Fragment>
  );
};

export default memo(ZoomListener);
