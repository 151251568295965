import { fabric } from 'fabric';
import { Fragment, memo } from 'react';
import { DrawingToolSettings } from '../../VisionBoard/Tools/DrawingTools';
import { TextToolSettings } from '../../VisionBoard/Tools/TextTools';
import { DrawingObject } from '../FabricDrawing';
import FabricListener from '../FabricListener';
import { PhotoObject } from '../FabricPhoto/FabricPhoto';
import { TextObject } from '../FabricText';

interface SelectionListenerProps {
  setEditingDrawingId: (id?: string) => void;
  setDrawingToolSettings: React.Dispatch<React.SetStateAction<DrawingToolSettings>>
  setEditingTextId: (id?: string) => void;
  setTextToolSettings: React.Dispatch<React.SetStateAction<TextToolSettings>>
}

const SelectionListener: React.FC<SelectionListenerProps> = ({ setEditingDrawingId, setDrawingToolSettings, setEditingTextId, setTextToolSettings }) => {
  const selectionCreatedHandler = ({ target }: fabric.IEvent) => {
    if (target instanceof DrawingObject && target.pvDrawingId) {
      const { stroke, strokeWidth } = target;
      setEditingDrawingId(target.pvDrawingId);
      setDrawingToolSettings({ stroke, strokeWidth });
    }

    if (target instanceof TextObject && target.pvId) {
      setEditingTextId(target.pvId);
      const { fill, fontWeight, fontStyle, textAlign, fontSize } = target;
      setTextToolSettings({ fill, fontStyle, fontWeight, textAlign, fontSize });
    }

    // This prevents resizing multiple objects selected at once
    if (!(target instanceof PhotoObject || target instanceof DrawingObject || target instanceof TextObject)) {
      target?.set('hasControls', false);
    }
  };

  const selectionClearedHandler = () => {
    setEditingDrawingId();
    setEditingTextId();
  };

  const selectionUpdatedHandler = (event: fabric.IEvent) => {
    if (!(event.target instanceof DrawingObject)) {
      setEditingDrawingId();
    }

    if (!(event.target instanceof TextObject)) {
      setEditingTextId();
    }

    selectionCreatedHandler(event);
  };

  return (
    <Fragment>
      <FabricListener
        eventname="selection:cleared"
        handler={selectionClearedHandler}
      />
      <FabricListener
        eventname="selection:created"
        handler={selectionCreatedHandler}
      />
      <FabricListener
        eventname="selection:updated"
        handler={selectionUpdatedHandler}
      />
    </Fragment>
  );
};

export default memo(SelectionListener);
