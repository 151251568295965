import { fabric } from 'fabric';
import { useContext, useEffect, memo, useRef } from 'react';
import { Peer } from '../../../hooks/useVisionBoard';
import { Profile } from '../../VisionBoard/VisionBoard';
import CanvasContext from '../CanvasContext';
import { createFabricShadowMedium } from '../constants';
import { applyCustomCorners, ensureObjectIsOnScreen } from '../helpers';
import createPhotoControls from './createPhotoControls';

interface FabricPhotoProps {
  src: string;
  top: number;
  left: number;
  angle: number;
  scale?: number;
  filters?: fabric.IBaseFilter[];
  name: string;
  controller?: Peer<Profile>;
  mondayItemId: number;
}

const CONTROL_VISIBILTY = {
  mb: false,
  mr: false,
  ml: false,
  mt: false
};

export class PhotoObject extends fabric.Group {
  pvItemId?: string;
  pvMondayItemId?: number;
}

const getImageObject = (photo: PhotoObject) => photo.item(1) as unknown as fabric.Image;
const getFrameObject = (photo: PhotoObject) => photo.item(0) as unknown as fabric.Rect;

const FabricPhoto: React.FC<FabricPhotoProps> = ({ src, top, left, angle, filters = [], scale = 1, name, controller, mondayItemId }) => {
  const canvas = useContext(CanvasContext);
  const photoRef = useRef<PhotoObject>();

  const setFilters = (photo: PhotoObject) => {
    const image = getImageObject(photo);
    image.set('filters', filters);
    image.applyFilters();
  };

  const applyChanges = (photo: PhotoObject) => {
    setFilters(photo);
    // TODO: investigate performance setting all at once vs individual useEffects
    photo.set({
      angle,
      top,
      left,
      scaleX: scale,
      scaleY: scale
    });

    // TODO: Update image if src changed

    ensureObjectIsOnScreen(photo);

    canvas.requestRenderAll();
  };

  useEffect(() => {
    const photo = photoRef.current;
    if (!photo) {
      return;
    }

    photo.set('selectable', !controller);

    getFrameObject(photo).set('stroke', controller?.profile.color);
    if (controller) {
      photo.bringToFront();
      photo.sendBackwards(); // This is a hack to push the photo back behind the user
    }

    canvas.requestRenderAll();
  }, [controller]);

  if (photoRef.current) {
    applyChanges(photoRef.current);
  }

  useEffect(() => {
    fabric.Image.fromURL(src, (image) => {
      const element = image.getElement();
      if (Object.prototype.toString.call(element) === '[object Object]' && !Object.keys(element).length) {
        return;
      }

      const imageScale = 200 / image.width!;
      const scaledImageHeight = image.height! * imageScale;
      image.set({
        top: 10,
        left: 10,
        scaleX: imageScale,
        scaleY: imageScale
      });
      const rectangle = new fabric.Rect({
        height: scaledImageHeight + 60,
        width: 220,
        fill: 'white',
        shadow: createFabricShadowMedium(),
        stroke: controller?.profile.color,
        strokeUniform: true
      });

      const imageControls = createPhotoControls();
      imageControls.set({
        top: scaledImageHeight + 20,
        left: 160,
        opacity: 0,
        subTargetCheck: true
      });

      const photo = new PhotoObject([rectangle, image, imageControls], {
        scaleX: scale,
        scaleY: scale,
        angle,
        lockScalingFlip: true,
        top,
        left,
        hoverCursor: 'pointer',
        subTargetCheck: true,
        originX: 'center',
        originY: 'center',
        centeredScaling: true
      });

      applyCustomCorners(photo);

      photo.pvMondayItemId = mondayItemId;
      photo.pvItemId = name;
      photo.setControlsVisibility(CONTROL_VISIBILTY);
      photoRef.current = photo;
      setFilters(photo);

      canvas.add(photo);
    }, {
      crossOrigin: ''
      // crossOrigin: 'anonymous' // TODO: Sort out behaviours once images are proxied (needed for filters)
    });

    return () => { canvas.remove(photoRef.current!); };
  }, []);

  return null;
};

export default memo(FabricPhoto);
