import { StrictMode, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import 'monday-ui-react-core/dist/main.css';
import './index.scss';
import DashboardWidget from './components/DashboardWidget/DashboardWidget';
import VisionBoard from './components/VisionBoard/VisionBoard';
import ItemView from './components/ItemView/ItemView';
import { useMondaySdk } from './hooks/useMondaySdk';

const VisionBoardWrapper = () => {
  const [sessionToken, setSessionToken] = useState<string>();
  const [boardId, setBoardId] = useState<string>();
  const monday = useMondaySdk();

  const loadToken = async () => {
    const { data: token } = await monday.get('sessionToken');
    const { data: context } = await monday.get('context');
    setSessionToken(token);
    setBoardId(context.boardId.toString());
  };

  useEffect(() => {
    loadToken();
  }, []);

  return (sessionToken && boardId)
    ? <VisionBoard boardId={boardId} sessionToken={sessionToken} />
    : null;
};

const getComponent = () => {
  const pageId = window.location.pathname.match(/boards\/vision-board|items\/products/)?.[0];

  switch (pageId) {
    case 'board':
      return <DashboardWidget />;
    case 'items/products':
      return <ItemView />;
    case 'boards/vision-board':
      return <VisionBoardWrapper />;
    default:
      return <div>not found</div>;
  }
};

ReactDOM.render(
  <StrictMode>
    {getComponent()}
  </StrictMode>,
  document.getElementById('root')
);
