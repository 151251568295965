import { fabric } from 'fabric';
import { useContext, useEffect, memo, useRef } from 'react';
import { Peer } from '../../hooks/useVisionBoard';
import { TextToolSettings } from '../VisionBoard/Tools/TextTools';
import { Profile } from '../VisionBoard/VisionBoard';
import CanvasContext from './CanvasContext';
import { applyCustomCorners, ensureObjectIsOnScreen } from './helpers';

interface FabricTextProps extends TextToolSettings {
  text: string;
  top: number;
  left: number;
  width: number;
  angle: number;
  id: string;
  controller?: Peer<Profile>;
  isEditing?: boolean;
}

const CONTROL_VISIBILTY = {
  bl: false,
  br: false,
  mb: false,
  mt: false,
  tl: false,
  tr: false
};

export class TextObject extends fabric.Textbox {
  pvId?: string;
}

const FabricText: React.FC<FabricTextProps> = ({ id, top, left, controller, text, width, isEditing, angle, fontStyle = 'normal', fontWeight = 'normal', textAlign = 'left', fill = '#323338', fontSize = 24 }) => {
  const canvas = useContext(CanvasContext);
  const textRef = useRef<TextObject>();

  const applyChanges = (textObject: TextObject) => {
    textObject.set({
      top,
      left,
      width,
      text,
      angle,
      fontStyle,
      fontWeight,
      textAlign,
      fontSize
    });

    // If it's a new text object, start editing it straight away
    if (isEditing && !textObject.isEditing && !text) {
      canvas.setActiveObject(textObject);
      textObject.enterEditing();
    }

    ensureObjectIsOnScreen(textObject);

    canvas.requestRenderAll();

    textObject.animate('fill', fill, {
      easing: fabric.util.ease.easeInSine,
      onChange: canvas.renderAll.bind(canvas),
      duration: 200
    });
  };

  useEffect(() => {
    const textObject = textRef.current;
    if (!textObject) {
      return;
    }

    // TODO: set come color indication of who the controller is. Maybe add fabric.rect with same dimentions with outline?
    if (controller) {
      textObject.bringToFront();
      textObject.sendBackwards(); // This is a hack to push the photo back behind the user
    }

    canvas.requestRenderAll();
  }, [controller]);

  if (textRef.current) {
    applyChanges(textRef.current);
  }

  useEffect(() => {
    const createObject = () => {
      const textObject = new TextObject(text, {
        top,
        left,
        width,
        angle,
        originX: 'center', // TODO: between different origins based on text alignment. Neesd to look right for user and for observer
        originY: 'center', // TODO: investigate only originX to prevent weird movements when adding new lines (rotation needs to work on user and for observer)
        fontFamily: 'roboto,sans-serif',
        fontWeight,
        fontStyle,
        textAlign,
        fill,
        fontSize
      });

      // TODO: investigate why rotation is broken on brand new text boxes (after creating)

      applyCustomCorners(textObject);

      textObject.pvId = id;
      textRef.current = textObject;
      textObject.setControlsVisibility(CONTROL_VISIBILTY);

      canvas.add(textObject);

      if (isEditing) {
        textObject.enterEditing();
      }
    };

    // Existing texts need to wait until font is loaded. Waiting for new texts breaks auto-editing
    if (text) {
      (document as any).fonts.load('16px "roboto"')
        .then(() => createObject());
    } else {
      createObject();
    }

    return () => { canvas.remove(textRef.current!); };
  }, []);

  return null;
};

export default memo(FabricText);
